<template>
    <div>
        <v-container class="my-5">
            <v-row>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="350px"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-model="date"
                            readonly
                            v-on="on"
                            color="indigo"
                            dark
                            class="mr-5 ml-3 mb-4"
                        >
                            {{ date }}
                        </v-btn>
                    </template>

                    <v-date-picker
                        v-model="date"
                        scrollable
                        type="month"
                        @change="dateChange(date)"
                    >
                        <v-spacer></v-spacer>

                        <v-btn text color="primary" @click="modal = false">
                            Anuluj
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-row>

            <v-row>
                <v-col>
                    <v-card
                        class="mx-auto"
                        v-if="tutorCost > 0"
                    >
                        <v-card-title
                            >{{ tutorCost.toFixed(2) }} zł</v-card-title
                        >
                        <v-card-subtitle class="text-start"
                            >Twoje przychody z sekcji kursanci copywritingu
                        </v-card-subtitle>
                    </v-card>
                    <v-card
                        class="mx-auto mt-3"
                        v-if="tutorCourseCost > 0"
                    >
                        <v-card-title
                            >{{ tutorCourseCost.toFixed(2) }} zł</v-card-title
                        >
                        <v-card-subtitle class="text-start"
                            >Twoje przychody z sekcji kursanci korekty
                        </v-card-subtitle>
                    </v-card>
                    <v-card class="mx-auto mt-3">
                        <v-card-title>{{ calcFullRate }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Twoje przychody łącznie</v-card-subtitle
                        >
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import io from "socket.io-client";

const token = localStorage.getItem('user');

export default {
    data() {
        return {
            tasks: [],
            socket: io(this.url),
            date: this.$route.params.month,
            id: this.$route.params.id,
            modal: false,
            tutorCost: 0,
            tutorCourseCost: 0,
            tutor: "",
            courseTutor: "",
            tutorList: []
        };
    },
    methods: {
        getTutorList(){
            this.$axios.get(`${this.url}api/tutors`, { headers: { 'x-access-token': token } })
            .then((el)=>{
                this.tutorList = el.data[0].list;
                console.log(this.tutorList)
            }).catch((err)=>{
                console.log(err);
            })
        },
        getCourseTutor() {
            this.$axios
                .get(`${this.url}api/users/course-tutor`, { headers: { 'x-access-token': token }})
                .then((el) => {
                    this.courseTutor = el.data.email;
                })
                .catch(() => {
                    this.isLoaded = false;
                });
        },
        getAllAppretices() {
            this.$axios
                .get(`${this.url}api/apprentice/payment/${this.date}/${this.email}`, { headers: { 'x-access-token': token }})
                .then((el) => {
                    let data = this.date.split("-");
                    let month = parseInt(data[1]);
                    let year = parseInt(data[0]);

                    if (year > 2024 || (year === 2024 && month >= 2)) {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 7.5;
                    } else if (year === 2023) {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if (year >= 2022 && month >= 10) {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if(year >= 2022 && month >= 4) {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 3;
                    } else {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 2;
                    }
                });
        },
        getAllCourse() {
            this.$axios
                .get(`${this.url}api/course/payment/${this.date}/${this.email}`, { headers: { 'x-access-token': token }})
                .then((el) => {
                    let data = this.date.split("-");
                    let month = parseInt(data[1]);
                    let year = parseInt(data[0]);


                    if (year > 2024 || (year === 2024 && month >= 2)) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 7.5;
                    } else if (year === 2023) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if (year >= 2022 && month >= 10) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if(year >= 2022 && month >= 4) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 3;
                    } else {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 2;
                    }
                });
        },
        getTutor() {
            this.$axios
                .get(`${this.url}api/users/practice-tutor`, { headers: { 'x-access-token': token }})
                .then((response) => {
                    this.$axios
                        .get(
                            `${this.url}api/apprentice/current-tutor/${response.data.email}`, { headers: { 'x-access-token': token }}
                        )
                        .then((el) => {
                            if (el.data) {
                                if (el.data.tutor) {
                                    this.tutor = el.data.tutor.email;
                                }
                            }
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPosts() {
            this.$axios
                .get(
                    `${this.url}api/tasks/payment/korektor/${
                        this.date
                    }/${localStorage.getItem("id")}`, { headers: { 'x-access-token': token }}
                )
                .then((resp) => {
                    this.tasks = resp.data;
                });
        },
        // New
        dateChange(date) {
            this.$router.push(
                `../../../korektor/payments/${date}/${localStorage.getItem(
                    "id"
                )}`
            );
            this.getPosts();
            this.getAllAppretices();
            this.getAllCourse();
            this.getTutor();
            this.getCourseTutor();
            this.getTutorList();
        },
    },
    created() {
        this.getPosts();
        this.getAllAppretices();
        this.getAllCourse();
        this.getTutor();
        this.getCourseTutor();
        this.getTutorList();
        this.socket.on("newTaskAdded", () => {
            this.getPosts();
        });

        this.socket.on("taskPaidUpdate", () => {
            this.getPosts();
        });

        this.socket.on("taskDeleted", () => {
            this.getPosts();
        });
        this.socket.on("newTaskAprrenticeEdited", () => {
            this.getPosts();
            this.getAllAppretices();
        });
        this.socket.on("appreticeChangedStatus", () => {
            this.getPosts();
            this.getAllAppretices();
        });
        this.socket.on("newTaskCourseEdited", () => {
            this.getPosts();
            this.getAllCourse();
        });
        this.socket.on("courseChangedStatus", () => {
            this.getPosts();
            this.getAllCourse();
        });
    },
    computed: {
        calcFullRate() {
            let allTasks = this.tasks;
            let result = [];
            result = allTasks.reduce(function (previousValue, currentValue) {
                if (currentValue.corectorRate) {
                    return (
                        previousValue +
                        (currentValue.corectorRate * currentValue.characters) /
                            1000
                    );
                } else {
                    return previousValue + currentValue.characters / 1000;
                }
            }, 0);

            // console.log(this.courseTutor);
            // if(this.tutor == this.email){
                result += this.tutorCost;
            // }
            // if (this.courseTutor == this.email) {
                result += this.tutorCourseCost;
            // }

            console.log(allTasks);
            result = result.toFixed(2);
            return result;
            // return (result + this.tutorCost + this.tutorCourseCost).toFixed(2);
            // return this.tutor == this.email ? (result + this.tutorCost).toFixed(2) : result.toFixed(2);
        },
        email() {
            return this.$store.getters.getUserMail;
        },
    },
};
</script>
